import Home from './pages'


function App() {
  return (
    
    <Home/>
  );
}

export default App;
